import axios from "axios";

const apiURL = axios.create({
  // baseURL: 'http://localhost:8000/api/v1/student',
  baseURL: 'https://admin.aspiraskillhub.aspirasys.com/api/v1/student',
});

// let timeout;

// const resetTimeout = () => {
//   clearTimeout(timeout);
//   timeout = setTimeout(() => {
//     // Perform logout actions here
//     localStorage.removeItem('token');
//     // Redirect to login page if needed
//     window.location.href = '/login';
//   }, 10 * 60 * 1000); // 10 minutes (10 * 60 * 1000 milliseconds)
// };

apiURL.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiURL.interceptors.response.use(
  (response) => {
    // resetTimeout(); // Reset timeout on successful response
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('token');
      // Redirect to login page if needed
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

// Event listeners to reset timeout on user activity
// document.addEventListener('keydown', resetTimeout);
// document.addEventListener('mousemove', resetTimeout);

// Initialize timeout
// resetTimeout();

export default apiURL;
